import React, { useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import "./photos.css";

const StyledDiv = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
`;

const StyledSliderContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
`;

const StyledSlider = styled(Slider)`
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  figure {
    padding: 0;
    margin: 0;
  }
`;

const StyledBackdropDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
`;

const StyledPhotoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;

  a {
    width: 50%;
    padding: 10px;
    margin: 0;
    cursor: pointer;

    @media (min-width: 768px) {
      width: 25%;
    }

    figure {
      position: relative;
      padding: 0;
      margin: 0;
    }

    img {
      max-width: 100%;
    }
  }
`;

const StyledCloseButton = styled.button`
  position: relative;
  cursor: pointer;
  align-self: flex-end;
  padding: 4px 8px;
  font-size: 32px;
  color: white;
  line-height: 1;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  outline: none;
`;

const Photos = () => {
  const [sliderActive, setSliderActive] = useState(false);
  const [sliderActiveIndex, setSliderActiveIndex] = useState(0);

  const settings = {
    arrows: true,
    initialSlide: sliderActiveIndex,
  };

  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { ext: { eq: ".jpg" }, relativeDirectory: { eq: "gallery" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                src
                originalName
              }
            }
          }
        }
      }
    }
  `);

  const dataSorted = data.allFile.edges.sort((imgA, imgB) =>
    imgA.node.childImageSharp.fluid.originalName >
    imgB.node.childImageSharp.fluid.originalName
      ? 1
      : -1
  );

  const handlePhotoClick = (event, index) => {
    // e.preventDefault();
    // console.log("slider index is ", index, event);
    setSliderActiveIndex(index);
    setSliderActive(!sliderActive);
  };

  const handleSliderClick = (event) => {
    console.log("slider clicked", Slider.slickGoTo);
    setSliderActive(false);
  };

  return (
    <StyledDiv id="photos">
      <StyledPhotoList {...settings}>
        {dataSorted.map((img, index) => {
          console.log(img, index, "image and index");
          const { id, childImageSharp } = img.node;
          const { fluid } = childImageSharp;
          const { originalName } = fluid;
          return (
            <a
              key={`img-${index}`}
              onClick={(event) => handlePhotoClick(event, index)}
            >
              <figure>
                <img src={fluid.src} alt={originalName} />
                {/* <StaticImage src={fluid.src} alt="1708 Travis Heights Blvd" /> */}
              </figure>
            </a>
          );
        })}
      </StyledPhotoList>
      {sliderActive && (
        <StyledSliderContainer>
          <StyledBackdropDiv onClick={(e) => handleSliderClick(e)} />
          <StyledCloseButton
            type="button"
            onClick={() => setSliderActive(false)}
          >
            &times;
          </StyledCloseButton>
          <StyledSlider {...settings}>
            {dataSorted.map((img, index) => {
              const { id, childImageSharp } = img.node;
              const { fluid } = childImageSharp;
              const { originalName } = fluid;
              return (
                <a key={`img-${index}`}>
                  <figure>
                    <img src={fluid.src} alt={originalName} />
                    {/* <StaticImage src={fluid.src} alt="1708 Travis Heights Blvd" /> */}
                  </figure>
                </a>
              );
            })}
          </StyledSlider>
        </StyledSliderContainer>
      )}
    </StyledDiv>
  );
};

export default Photos;
