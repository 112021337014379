import React from "react";
import styled from "styled-components";
import Details from "../components/Details";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Photos from "../components/Photos";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const StyledMapDiv = styled.div`
  display: flex;
  justify-content: center;
`;

// markup
const IndexPage = () => {
  return (
    <Layout>
      <main style={pageStyles} id="top">
        <title>Home Page</title>
        <Header />
        <Details />
        <Photos />
        <StyledMapDiv id="map">
          <iframe
            id="kindred-1708-travis-heights-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3446.78946655367!2d-97.74494108424504!3d30.243079716115233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644b4f86b7ceab7%3A0x129e75a193e8eb2d!2s1708%20Travis%20Heights%20Blvd%2C%20Austin%2C%20TX%2078704!5e0!3m2!1sen!2sus!4v1616867153968!5m2!1sen!2sus"
            width="2400"
            height="450"
            allowfullscreen=""
            loading="lazy"
            style={{ border: "none" }}
          ></iframe>
        </StyledMapDiv>
        <Footer />
      </main>
    </Layout>
  );
};

export default IndexPage;
