import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import Container from "../components/Container";
import coverImage from "../images/cover-image.jpg";
import KindredLogo from "../icons/logo";

const StyledHeader = styled(BackgroundImage)`
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  background-position: center center;
  background-size: cover;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

const StyledHeaderContainer = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
`;

const StyledNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  a {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    text-decoration: none;
    text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid transparent;
    transition: all 200ms ease-in-out;

    &:nth-of-type(2) {
      margin-left: auto;
    }

    + a {
      margin-left: 15px;
    }
  }
`;

const StyledAddress = styled.address`
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: auto;
  margin-bottom: auto;
  font-style: normal;
  font-size: 36px;
  font-weight: bold;
  color: white;
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.5);
`;

const styledSpan = {
  fontSize: "18px",
  fontWeight: "400",
};

const Header = ({ children, className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "cover-image.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <StyledHeader
      Tag="header"
      className={className}
      fluid={imageData}
      backgroundColor={`#fff`}
    >
      <StyledHeaderContainer>
        <StyledNav>
          <a href="#top">
            <KindredLogo />
          </a>
          <a href="#overview">Overview</a>
          <a href="#photos">Photos</a>
          <a href="#map">Map</a>
        </StyledNav>
        <StyledAddress>
          1708 Travis Heights Blvd
          <br />
          <span style={styledSpan}>Austin, TX 78704</span>
        </StyledAddress>
      </StyledHeaderContainer>
    </StyledHeader>
  );
};

export default Header;
