import React from "react";
import styled from "styled-components";
import Container from "../components/Container";
import { FacebookLogo, InstagramLogo, LinkedInLogo } from "../icons/icons";

const StyledFooter = styled.footer`
  padding-top: 30px;
  padding-bottom: 30px;
`;

const StyledFooterContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const StyledCopy = styled.span``;

const StyledSocialLinks = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0 5px;
  }

  a {
    text-decoration: none;
  }
`;

const Footer = () => (
  <StyledFooter>
    <StyledFooterContainer>
      <StyledCopy>&copy;&nbsp;KindredATX</StyledCopy>
      <StyledSocialLinks>
        <li>
          <a
            href="//www.facebook.com/KindredATX/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FacebookLogo width="30" height="30" />
          </a>
        </li>
        <li>
          <a
            href="//www.instagram.com/kindredatx/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <InstagramLogo width="30" height="30" />
          </a>
        </li>
        <li>
          <a
            href="//www.linkedin.com/company/kindred-realty-group/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <LinkedInLogo width="30" height="30" />
          </a>
        </li>
      </StyledSocialLinks>
    </StyledFooterContainer>
  </StyledFooter>
);

export default Footer;
