import React from "react";
import styled from "styled-components";
import Container from "./Container";
import { BathIcon, BedIcon, GarageIcon, SizeIcon } from "../icons/icons";

const StyledDiv = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`;

const StyledPrice = styled.p`
  padding-bottom: 30px;
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #ddd;
`;

const StyledFeatureList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    text-align: center;

    @media (max-width: 768px) {
      flex-basis: 50%;
      margin-bottom: 30px;
    }
  }
`;

const StyledDetailsContainer = styled.div`
  font-size: 18px;
`;

const Details = () => (
  <StyledDiv id="overview">
    <Container>
      <StyledPrice>$1,500,000</StyledPrice>
      <StyledFeatureList>
        <li>
          <SizeIcon width="60" height="60" />
          <p>1,724 sq. ft.</p>
        </li>
        <li>
          <BedIcon width="60" height="60" />
          <p>3 beds</p>
        </li>
        <li>
          <BathIcon width="60" height="60" />
          <p>2 baths</p>
        </li>
        <li>
          <GarageIcon width="60" height="60" />
          <p>2 car garage</p>
        </li>
      </StyledFeatureList>
      <StyledDetailsContainer>
        <p>
          No detail or expense spared in this gorgeous, one of a kind Travis
          Heights remodel. Incredible attention to detail in every space from
          the custom lighting and luxe finishes, to the motorized drapes, and
          high end appliances. The home features a thoughtful primary bedroom
          and bathroom addition that feels like a hotel with designer finishes
          hand selected for the space.
        </p>
        <p>
          An entertainer’s dream, this home features an incredible outdoor oasis
          with multiple spacious ipe deck spaces for dining and lounging, a
          separate fenced in front yard and covered patio, a dog run, and a
          heated and chilled pool. The mudroom and two car garage provide extra
          storage and functionality that aren’t easy to find!
        </p>
        <p>
          Stellar location in one of Austin’s most sought after and walkable
          neighborhoods. Blocks from South Congress, Ladybird Lake and Downtown
          with easy access to I35.
        </p>
      </StyledDetailsContainer>
    </Container>
  </StyledDiv>
);

export default Details;
