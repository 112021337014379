import React from "react";

const KindredLogo = ({ width }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1077.62 461.7"
    style={{ fill: "#fff" }}
    width={width ? width : "120"}
  >
    <g>
      <g>
        <g>
          <path d="M258.18,265.42a10.71,10.71,0,0,1,15.15-15.15L405.21,382.15A230.86,230.86,0,0,0,390.6,64.2a10.55,10.55,0,0,1-.89,1.11L205.4,253.91a10.71,10.71,0,0,1-15.32-15L374.39,50.33l.17-.14A231.16,231.16,0,0,0,151.32,14.07V308.32a10.72,10.72,0,0,1-21.43,0V23.2A230.85,230.85,0,0,0,0,230.85C0,358.35,103.36,461.7,230.85,461.7a230.07,230.07,0,0,0,159.58-64Z" />
          <g>
            <g>
              <path d="M620.89,247.71l-27.63-46.82-17,19.19v27.63h-9.76V128.92h9.76V208h.33l39.71-45.82h11.41l-28.12,31.93L632,247.71Z" />
              <path d="M648.18,143.48V128.92h11.91v14.56Zm1,104.23V162.18h9.76v85.53Z" />
              <path d="M741.15,247.71V187.65c0-13.23-4.63-19-15.88-19-9.1,0-16.55,3.8-28.13,16.21v62.87h-9.76V162.18h9.76v13.23c9.43-10.25,18.53-15.22,29.62-15.22q12.9,0,18.86,7.45c3.8,4.63,5.29,11.58,5.29,20.51v59.56Z" />
              <path d="M834.78,247.71l-2.15-10.59c-8.93,9.1-16.71,12.57-28.29,12.57-20.51,0-33.42-17.53-33.42-43.84,0-28,14.23-45,34.91-45,10.59,0,18.53,4,26,12.58V128.92h9.76V247.71Zm-3-62.37c-6.45-10.43-15.71-16.05-25.48-16.05-15.88,0-25.64,14.39-25.64,36.56,0,22,9.6,35.41,25.31,35.41,9.76,0,20.19-5.79,25.81-15.88Z" />
              <path d="M870.84,247.71V162.18h9.76v17.2c7.61-13.73,16.54-19.19,27-19.19a14.74,14.74,0,0,1,2.65.17v9.1a23.12,23.12,0,0,0-3-.17c-13.4,0-22,11.91-26.63,24.49v53.93Z" />
              <path d="M956.36,249.69c-21.34,0-35.07-16-35.07-44.17,0-27.79,14.56-45.33,35.24-45.33,22,0,34.25,16.21,34.25,40.53,0,1.16,0,2.65-.17,4.8H931.05c.5,22.17,7.94,35.74,26,35.74,10.59,0,19.36-4.3,25.81-17l7.61,4.3C982.67,243.57,970.59,249.69,956.36,249.69Zm-25.14-52.27h49.46c-.16-17.38-8.76-29.12-24.48-29.12C940.65,168.3,932.87,181.86,931.22,197.42Z" />
              <path d="M1070.84,247.71l-2.15-10.59c-8.94,9.1-16.71,12.57-28.29,12.57-20.52,0-33.42-17.53-33.42-43.84,0-28,14.23-45,34.91-45,10.59,0,18.53,4,26,12.58V128.92h9.76V247.71Zm-3-62.37c-6.45-10.43-15.72-16.05-25.48-16.05-15.88,0-25.64,14.39-25.64,36.56,0,22,9.6,35.41,25.31,35.41,9.76,0,20.19-5.79,25.81-15.88Z" />
            </g>
            <g>
              <path d="M593.38,331.13l-9.48-18.51H573v18.51h-3.5V290.6H581c10.33,0,14.73,3.78,14.73,10.9,0,5.64-3.27,8.92-8.35,10.33l10,19.3Zm-11.74-21.56c3.84,0,10.56-.73,10.56-8,0-6.94-5.71-7.9-10.67-7.9H573v15.92Z" />
              <path d="M617.19,331.13V290.6h23.15v3H620.69V308.5h14.17v3.05H620.69v16.54h20.72v3Z" />
              <path d="M683.63,331.13l-4.24-13.32H663.76l-4.24,13.32H656l13-40.53h5.19l13.1,40.53Zm-19-16.37h13.77l-6.83-21.62h-.11Z" />
              <path d="M706.14,331.13V290.6h3.5v37.49h18.24v3Z" />
              <path d="M750.17,331.13V293.65H738v-3H765.8v3H753.67v37.48Z" />
              <path d="M792.44,331.13V314L779.85,290.6h3.95l10.5,20.27h.11l10.73-20.27h3.78l-13,23.37v17.16Z" />
              <path d="M874.22,331.13v-5h-.11a12.43,12.43,0,0,1-10.78,5.64c-10.56,0-15.92-7.45-15.92-20.6,0-12.88,5.42-21.29,15.75-21.29,6.83,0,11.51,3.33,13.77,11.92l-3.27.73c-1.92-6.66-5.53-9.65-10.5-9.65-7.45,0-12.25,7-12.25,18.34s4.34,17.56,12.42,17.56c6.49,0,10.72-4.23,10.72-11.4a15.59,15.59,0,0,0-.11-2.15H863.33v-3h13.94v18.91Z" />
              <path d="M923,331.13l-9.49-18.51H902.61v18.51H899.1V290.6h11.52c10.33,0,14.74,3.78,14.74,10.9,0,5.64-3.28,8.92-8.36,10.33l10,19.3Zm-11.75-21.56c3.84,0,10.56-.73,10.56-8,0-6.94-5.7-7.9-10.67-7.9h-8.52v15.92Z" />
              <path d="M943.8,310.87c0-13.21,6.21-20.95,16.09-20.95S976,297.66,976,310.87s-6.21,20.94-16.09,20.94S943.8,324.08,943.8,310.87Zm28.68,0c0-11.24-5.14-17.95-12.59-17.95s-12.58,6.71-12.58,17.95,5.13,17.95,12.58,17.95S972.48,322.1,972.48,310.87Z" />
              <path d="M1021.08,290.6h3.5v25.12c0,9.94-4.29,16.09-14.85,16.09s-14.11-6.15-14.11-16.09V290.6h3.5v25.69c0,8.52,3,12.53,10.61,12.53s11.35-4,11.35-12.53Z" />
              <path d="M1047.2,331.13V290.6h11.35c9.76,0,14.68,3.5,14.68,11.12s-4.8,11.35-15.36,11.35h-7.17v18.06ZM1058.27,310c4.8,0,11.46-.56,11.46-8.3,0-5.47-3.73-8.07-10.79-8.07h-8.24V310Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default KindredLogo;
