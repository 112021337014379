import React from "react";

export const FacebookLogo = ({ width, height }) => (
  <svg
    id="facebook"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    width={width ? width : "20"}
    height={height ? height : "20"}
  >
    <g>
      <path d="M1024,512C1024,229.23,794.77,0,512,0S0,229.23,0,512c0,255.55,187.23,467.37,432,505.78V660H302V512H432V399.2C432,270.88,508.44,200,625.39,200c56,0,114.61,10,114.61,10V336H675.44c-63.6,0-83.44,39.47-83.44,80v96H734L711.3,660H592v357.78C836.77,979.37,1024,767.55,1024,512Z" />
      <path
        style={{ fill: "#fff" }}
        d="M711.3,660,734,512H592V416c0-40.49,19.84-80,83.44-80H740V210s-58.59-10-114.61-10C508.44,200,432,270.88,432,399.2V512H302V660H432v357.78a517.58,517.58,0,0,0,160,0V660Z"
      />
    </g>
  </svg>
);

export const InstagramLogo = ({ width, height }) => (
  <svg
    id="instagram"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 503.84 503.84"
    width={width ? width : "20"}
    height={height ? height : "20"}
  >
    <g>
      <path
        d="M256,49.47c67.27,0,75.23.26,101.8,1.47,24.56,1.12,37.9,5.22,46.78,8.67a78,78,0,0,1,29,18.85,78,78,0,0,1,18.85,29c3.45,8.88,7.55,22.22,8.67,46.78,1.21,26.57,1.47,34.53,1.47,101.8s-.26,75.23-1.47,101.8c-1.12,24.56-5.22,37.9-8.67,46.78a83.51,83.51,0,0,1-47.81,47.81c-8.88,3.45-22.22,7.55-46.78,8.67-26.56,1.21-34.53,1.47-101.8,1.47s-75.24-.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.78-8.67a78,78,0,0,1-29-18.85,78,78,0,0,1-18.85-29c-3.45-8.88-7.55-22.22-8.67-46.78-1.21-26.57-1.47-34.53-1.47-101.8s.26-75.23,1.47-101.8c1.12-24.56,5.22-37.9,8.67-46.78a78,78,0,0,1,18.85-29,78,78,0,0,1,29-18.85c8.88-3.45,22.22-7.55,46.78-8.67,26.57-1.21,34.53-1.47,101.8-1.47m0-45.39c-68.42,0-77,.29-103.87,1.52S107,11.08,91,17.3A123.68,123.68,0,0,0,46.36,46.36,123.68,123.68,0,0,0,17.3,91c-6.22,16-10.48,34.34-11.7,61.15S4.08,187.58,4.08,256s.29,77,1.52,103.87S11.08,405,17.3,421a123.68,123.68,0,0,0,29.06,44.62A123.52,123.52,0,0,0,91,494.69c16,6.23,34.34,10.49,61.15,11.71s35.45,1.52,103.87,1.52,77-.29,103.87-1.52S405,500.92,421,494.69A128.74,128.74,0,0,0,494.69,421c6.23-16,10.49-34.34,11.71-61.15s1.52-35.45,1.52-103.87-.29-77-1.52-103.87S500.92,107,494.69,91a123.52,123.52,0,0,0-29.05-44.62A123.68,123.68,0,0,0,421,17.3c-16-6.22-34.34-10.48-61.15-11.7S324.42,4.08,256,4.08Z"
        transform="translate(-4.08 -4.08)"
      />
      <path
        d="M256,126.64A129.36,129.36,0,1,0,385.36,256,129.35,129.35,0,0,0,256,126.64ZM256,340a84,84,0,1,1,84-84A84,84,0,0,1,256,340Z"
        transform="translate(-4.08 -4.08)"
      />
      <circle cx="386.4" cy="117.44" r="30.23" />
    </g>
  </svg>
);

export const LinkedInLogo = ({ width, height }) => (
  <svg
    id="linkedin"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width ? width : "20"}
    height={height ? height : "20"}
  >
    <path d="M19,0H5A5,5,0,0,0,0,5V19a5,5,0,0,0,5,5H19a5,5,0,0,0,5-5V5A5,5,0,0,0,19,0ZM8,19H5V8H8ZM6.5,6.73A1.77,1.77,0,1,1,8.25,5,1.75,1.75,0,0,1,6.5,6.73ZM20,19H17V13.4c0-3.37-4-3.12-4,0V19H10V8h3V9.77c1.4-2.59,7-2.78,7,2.47Z" />
  </svg>
);

export const BathIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 34"
    width={width ? width : "20"}
    height={height ? height : "20"}
  >
    <g>
      <path
        style={{ fill: "#000" }}
        d="M49,14H7V6a4,4,0,0,1,8,0,1,1,0,0,0,2,0A6,6,0,0,0,5,6v8H1a1,1,0,0,0,0,2H2v7a9.91,9.91,0,0,0,2.74,6.85L2.29,32.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l2.54-2.54A9.94,9.94,0,0,0,12,33H38a9.94,9.94,0,0,0,5.75-1.83l2.54,2.54a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-2.45-2.44A9.91,9.91,0,0,0,48,23V16h1a1,1,0,0,0,0-2Zm-3,9a8,8,0,0,1-8,8H12a8,8,0,0,1-8-8V16H46Z"
      />
    </g>
  </svg>
);

export const BedIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 30"
    width={width ? width : "20"}
    height={height ? height : "20"}
  >
    <path
      style={{ fill: "#000" }}
      d="M49,4a1,1,0,0,0-1,1V15H46V9a5,5,0,0,0-5-5H17a5,5,0,0,0-3.64,1.6A5,5,0,0,0,4,8a4.92,4.92,0,0,0,1,3H2V1A1,1,0,0,0,0,1V29a1,1,0,0,0,2,0V21H48v8a1,1,0,0,0,2,0V5A1,1,0,0,0,49,4ZM17,6H41a3,3,0,0,1,3,3v6H14V9A3,3,0,0,1,17,6ZM5.9,8A3.1,3.1,0,1,1,9,11.1,3.1,3.1,0,0,1,5.9,8ZM2,13H12v2H2Zm46,6H2V17H48Z"
    />
  </svg>
);

export const GarageIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 47.99"
    width={width ? width : "20"}
    height={height ? height : "20"}
  >
    <g>
      <path
        class="cls-1"
        d="M38,48H37a3,3,0,0,1-3-3V44H14v1a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V43.82A3,3,0,0,1,5,41V33a3,3,0,0,1,2.5-3l1.73-6.36A5,5,0,0,1,14.06,20H33.94a5,5,0,0,1,4.83,3.69L40.5,30A3,3,0,0,1,43,33v8a3,3,0,0,1-2,2.83V45A3,3,0,0,1,38,48ZM13,42H35a1,1,0,0,1,1,1v2a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V43a1,1,0,0,1,1-1,1,1,0,0,0,1-1V33a1,1,0,0,0-1-1h-.28a1,1,0,0,1-1-.73l-1.92-7A3,3,0,0,0,33.94,22H14.06a3,3,0,0,0-2.9,2.22L9.24,31.26a1,1,0,0,1-1,.73H8a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1,1,1,0,0,1,1,1v2a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V43A1,1,0,0,1,13,42Z"
      />
      <path
        class="cls-1"
        d="M35.27,32a0,0,0,0,1,0,0H12.75a1,1,0,0,1-1-1.24l1.49-6a1,1,0,0,1,1-.76H33.76a1,1,0,0,1,1,.76l1.44,5.82a.87.87,0,0,1,.1.42A1,1,0,0,1,35.27,32ZM14,30H34l-1-4H15Z"
      />
      <path
        class="cls-1"
        d="M12,40a3,3,0,1,1,3-3A3,3,0,0,1,12,40Zm0-4.1A1.1,1.1,0,1,0,13.1,37,1.1,1.1,0,0,0,12,35.89Z"
      />
      <path
        class="cls-1"
        d="M36,40a3,3,0,1,1,3-3A3,3,0,0,1,36,40Zm0-4.1A1.1,1.1,0,1,0,37.1,37,1.1,1.1,0,0,0,36,35.89Z"
      />
      <path
        class="cls-1"
        d="M47,48a1,1,0,0,1-1-1V14.21a1,1,0,0,0-.54-.89L24.45,2.1a1,1,0,0,0-.91,0l-21,11.21a1,1,0,0,0-.55.89V47a1,1,0,0,1-2,0V14.21a3,3,0,0,1,1.61-2.66L22.6.34a3,3,0,0,1,2.79,0l21,11.21A3,3,0,0,1,48,14.21V47A1,1,0,0,1,47,48Z"
      />
    </g>
  </svg>
);

export const SizeIcon = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 47"
    width={width ? width : "20"}
    height={height ? height : "20"}
  >
    <path
      style={{ fill: "#000" }}
      d="M49.32,22.9,27.47,1.06a3.41,3.41,0,0,0-4.94,0L.68,22.9A2.33,2.33,0,0,0,0,24.56,2.38,2.38,0,0,0,2.38,27H6V44.5A2.5,2.5,0,0,0,8.5,47h33A2.5,2.5,0,0,0,44,44.5V27h3.62A2.38,2.38,0,0,0,50,24.56,2.33,2.33,0,0,0,49.32,22.9ZM20,45V35.5a.5.5,0,0,1,.5-.5h9a.5.5,0,0,1,.5.5V45ZM47.62,25H43a1,1,0,0,0-1,1V44.5a.5.5,0,0,1-.5.5H32V35.5A2.5,2.5,0,0,0,29.5,33h-9A2.5,2.5,0,0,0,18,35.5V45H8.5a.5.5,0,0,1-.5-.5V26a1,1,0,0,0-1-1H2.38C2,25,2,24.57,2,24.56a.29.29,0,0,1,.1-.24L23.94,2.48A1.51,1.51,0,0,1,25,2a1.54,1.54,0,0,1,1.06.47L47.9,24.32a.29.29,0,0,1,.1.24S48,25,47.62,25Z"
    />
  </svg>
);
